<script setup lang="ts">
import type { KeenSliderOptions } from "keen-slider";

import type { HomeBanners, BannerPresetPackage } from "@/types";

const props = defineProps<{
	banners: HomeBanners & BannerPresetPackage[];
	loading?: boolean;
}>();

const isGuest = useIsGuest();
const sliderRef = ref();
const isLoading = ref(false);

const sliderOptions: KeenSliderOptions<{ autoplay?: { delay: number } }> = {
	defaultAnimation: {
		duration: 1000
	},
	slides: { perView: 1, spacing: 24 },
	breakpoints: {
		"(max-width: 767px)": {
			slides: {
				spacing: 16
			}
		}
	},
	autoplay: { delay: 5000 }
};

watch(
	() => props.banners?.length,
	() => {
		nextTick(() => {
			sliderRef.value?.slider?.update();
		});
	}
);

onMounted(() => {
	isLoading.value = true;
});
</script>

<template>
	<div class="banners">
		<AAnimationSkeleton v-if="!isLoading">
			<ASkeleton class="skeleton" />
		</AAnimationSkeleton>
		<LazyASlider v-else ref="sliderRef" :options="sliderOptions">
			<template v-for="(banner, index) in banners" :key="`banner_${index}`">
				<OHomeBannerPresetPackage
					v-if="(banner as BannerPresetPackage) && banner?.type === 'bannerPresetPackage'"
					:id-banner="banner?.promoOfferPreset?.id || 0"
					:title="banner?.promoOfferPreset?.title || ''"
					:text="banner?.text || ''"
					:badge-label="banner?.promoOfferPreset?.badgeLabel"
					:img-desk="banner?.promoOfferPreset?.image2x || ''"
					:img-mob="banner?.promoOfferPreset?.imageMobile2x || ''"
					:bg-desk="banner?.promoOfferPreset?.imageBanner || ''"
					:bg-mob="banner?.promoOfferPreset?.imageBannerMobile || ''"
					:is-welcome-offer="banner?.isWelcomeOffer"
					:entries="banner?.promoOfferPreset?.entries || 0"
					:coins="banner?.promoOfferPreset?.coins || 0"
					:money="banner?.money || ''"
					:image-badge="banner?.promoOfferPreset?.imageBadge || ''"
					class="keen-slider__slide"
				/>

				<OHomeMainBanner
					v-else
					:img-desk="banner?.image2x || ''"
					:img-mob="banner?.imageMobile2x || ''"
					:bg-desk="banner?.backgroundImage || ''"
					:bg-mob="banner?.backgroundImage2x || ''"
					:title="banner?.title || ''"
					:small-title="banner?.smallTitle || ''"
					:button-text="banner?.buttonText || ''"
					:is-guest="isGuest"
					class="keen-slider__slide"
					:banner="banner as unknown as HomeBanners[number]"
					:button-link="banner?.buttonLink || ''"
				/>
			</template>

			<template #dots="{ slider, activeIndex }">
				<div v-if="slider && banners?.length > 1" class="pagination">
					<div
						v-for="index in banners.length"
						:key="index"
						:class="['pagination-item', { active: activeIndex === index - 1 }]"
						@click="slider && slider.moveToIdx(index - 1)"
					/>
				</div>
			</template>
		</LazyASlider>
	</div>
</template>

<style scoped lang="scss">
.banners {
	position: relative;
	min-height: 372px;

	.skeleton {
		height: 372px;
	}

	@include media-breakpoint-down(md) {
		padding: 0 gutter(2);
		min-height: 434px;

		.skeleton {
			height: 408px;
		}
	}

	&:deep(.keen-slider) {
		@include media-breakpoint-down(md) {
			overflow: visible !important;
		}
	}

	.keen-slider {
		&__slide {
			padding-top: gutter(4);
			padding-bottom: gutter(2);

			@include media-breakpoint-down(md) {
				padding-top: 0;
				overflow-y: visible;
			}
		}
	}

	.pagination {
		display: flex;
		gap: gutter(1);
		justify-content: center;

		@include media-breakpoint-down(md) {
			margin-top: gutter(2);
		}

		&-item {
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background-color: var(--cipolletti);
			cursor: pointer;

			&.active {
				pointer-events: none;
				background-color: var(--caracas);
			}
		}
	}
}
</style>
